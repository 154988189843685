<template>
    <section class="shop-settings">
        <tool-bar>
            Shop Settings
            <template slot="buttons">
                <button class="button"
                        v-bind:class="{ 'is-loading': loading }"
                        v-bind:disabled="!edited"
                        v-on:click="save">
                        <span class="icon is-small">
                            <font-awesome-icon icon="save"></font-awesome-icon>
                        </span>
                    <span>Save</span>
                </button>
            </template>
        </tool-bar>

        <div class="content">
            <div class="field">
                <label class="label is-marginless">Allowed payment methods at shop (applicable for products)</label>
                <p>This option controls how a customer can pay. </p>
            </div>
            <div class="field">
                <b-switch type="is-success"
                          v-model="allowCards">
                    Payment Cards
                </b-switch>
            </div>
            <div class="field">
                <b-switch type="is-success"
                          v-model="allowPaymentRequestAPI">
                    Payment Request API (Apple Pay, Google Pay, etc.)
                </b-switch>
            </div>
            <div class="field">
                <b-switch type="is-success"
                          v-model="allowPayPal">
                    PayPal
                </b-switch>
            </div>
            <div class="field">
                <b-switch type="is-success"
                          v-model="allowAliPay">
                    AliPay
                </b-switch>
            </div>
            <div class="field">
                <b-switch type="is-success"
                          v-model="allowWeChatPay">
                    WeChat Pay
                </b-switch>
            </div>
        </div>
        <div class="content">
            <div class="field">
                <label class="label is-marginless">Shop event notifications</label>
                <p>This option controls who are going to receive shop event notifications. Recipients can be any
                    administrator or email address. </p>
            </div>
            <div class="field">
                <label class="label">Topics</label>
                <b-switch type="is-success"
                          v-model="receiveOrderCreated">
                    Order Created
                </b-switch>
                <b-switch type="is-success"
                          v-model="receiveOrderPaid">
                    Order Paid
                </b-switch>
                <b-switch type="is-success"
                          v-model="receiveOrderDispatched">
                    Order Dispatched
                </b-switch>
                <b-switch type="is-success"
                          v-model="receiveOrderDelivered">
                    Order Delivered
                </b-switch>
            </div>
            <b-field label="Recipients">
                <b-taginput autocomplete
                            allow-new
                            field="email"
                            icon="label"
                            placeholder="Add a recipient"
                            v-model="recipients"
                            v-bind:data="filteredAdmins"
                            v-bind:before-adding="validateRecipient"
                            v-on:typing="getFilteredAdmins">
                </b-taginput>
            </b-field>
        </div>
    </section>
</template>

<script>
import isEmail from "validator/lib/isEmail";
import ToolBar from "../layouts/ToolBar";

export default {
    name: "ShopSettings",
    components: {
        ToolBar,
    },
    data() {
        return {
            loading: false,
            edited: false,

            allowPayOnSite: false,
            allowCards: false,
            allowPaymentRequestAPI: false,
            allowPayPal: false,
            allowAliPay: false,
            allowWeChatPay: false,

            recipients: [],
            filteredAdmins: [].concat(this.administrators),

            receiveOrderCreated: false,
            receiveOrderPaid: false,
            receiveOrderDispatched: false,
            receiveOrderDelivered: false
        }
    },
    computed: {
        paymentMethods () {
            return this.$store.state.shop.paymentMethods ?? undefined;
        },

        notificationOptions () {
            return this.$store.state.shop.notificationOptions ?? undefined;
        },

        administrators () {
            return this.$store.state.admin.admins
                .filter(
                    ({ role }) => role === "admin"
                );
        },
    },
    methods: {
        getFilteredAdmins (text) {
            this.filteredAdmins = this.administrators
                .filter(
                    ({ email }) => email.toString().toLowerCase().indexOf(text.toLowerCase()) >= 0
                );
        },

        validateRecipient (recipient) {
            return typeof recipient === "object" ?
                isEmail(recipient.email) :
                isEmail(recipient);
        },

        async save () {
            this.loading = true;
            const topics = [];
            if (this.receiveOrderCreated === true) {
                topics.push("created");
            }
            if (this.receiveOrderPaid === true) {
                topics.push("paid");
            }
            if (this.receiveOrderDispatched === true) {
                topics.push("dispatched");
            }
            if (this.receiveOrderDelivered === true) {
                topics.push("delivered");
            }
            try {
                await this.$store.dispatch(
                    "shop/updateShop",
                    {
                        "paymentMethods": {
                            "on_site": this.allowPayOnSite,
                            "cards": this.allowCards,
                            "payment_request_api": this.allowPaymentRequestAPI,
                            "paypal": this.allowPayPal,
                            "alipay": this.allowAliPay,
                            "wechat_pay": this.allowWeChatPay
                        },
                        "notificationOptions": {
                            "recipients": this.recipients,
                            topics,
                        }
                    }
                );
            } catch (e) {
                console.error(e);
                this.loading = false;
                return;
            }
            this.loading = false;
        }
    },
    mounted() {
        if (this.paymentMethods !== undefined) {
            this.allowPayOnSite = this.paymentMethods["on_site"];
            this.allowCards = this.paymentMethods["cards"];
            this.allowPaymentRequestAPI = this.paymentMethods["payment_request_api"];
            this.allowPayPal = this.paymentMethods["paypal"];
            this.allowAliPay = this.paymentMethods["alipay"];
            this.allowWeChatPay = this.paymentMethods["wechat_pay"];
        }

        if (this.notificationOptions !== undefined) {
            const {
                recipients,
                topics,
            } = this.notificationOptions;
            this.recipients = recipients
            this.receiveOrderCreated = topics.includes("created") ?? this.receiveOrderCreated;
            this.receiveOrderPaid = topics.includes("paid") ?? this.receiveOrderPaid;
            this.receiveOrderDispatched = topics.includes("dispatched") ?? this.receiveOrderDispatched;
            this.receiveOrderDelivered = topics.includes("delivered") ?? this.receiveOrderDelivered;
        }
    },
    watch: {
        paymentMethods (newVal) {
            if (newVal !== undefined) {
                this.allowPayOnSite = newVal["on_site"];
                this.allowCards = newVal["cards"];
                this.allowPaymentRequestAPI = newVal["payment_request_api"];
                this.allowPayPal = newVal["paypal"];
                this.allowAliPay = newVal["alipay"];
                this.allowWeChatPay = newVal["wechat_pay"];
            }
        },

        notificationOptions (
            { recipients, topics, }
        ) {
            this.recipients = recipients ?? [];
            this.receiveOrderCreated = topics?.includes("created") ?? this.receiveOrderCreated;
            this.receiveOrderPaid = topics?.includes("paid") ?? this.receiveOrderPaid;
            this.receiveOrderDispatched = topics?.includes("dispatched") ?? this.receiveOrderDispatched;
            this.receiveOrderDelivered = topics?.includes("delivered") ?? this.receiveOrderDelivered;
        },

        "allowPayOnSite" () {
            this.edited = true;
        },

        "allowCards" () {
            this.edited = true;
        },

        "allowPaymentRequestAPI" () {
            this.edited = true;
        },

        "allowPayPal" () {
            this.edited = true;
        },

        "allowAliPay" () {
            this.edited = true;
        },

        "allowWeChatPay" () {
            this.edited = true;
        }
    }
}
</script>

<style lang="scss" scoped>
.shop-settings {
    & > .level {
        background-color: rgba(255, 255, 255, 1);
        padding: 0.75rem 1rem;
        margin-bottom: 0;
        box-shadow: 0 0 1px rgba(125, 125, 125, 1);

        position: sticky;
        top: 0;
        z-index: 9;

        .label {
            font-size: 20px;
        }
    }

    & > .content {
        padding: 1rem;

        .field {
            & > p {
                margin-bottom: 10px;
            }
        }

        .panel {
            max-width: 350px;
            margin-top: 10px;

            & > .panel-heading {
                font-size: 16px;
            }
        }
    }

    .w-100 {
        width: 100%;
    }
}
</style>
